import { Injectable } from '@angular/core';
import { posthog } from 'posthog-js';

export type FeatureFlagKey = 'ENABLE_NEW_BANNER';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  getFeatureFlag(featureFlagKey: FeatureFlagKey) {
    return !!this.isFeatureFlagEnabled(featureFlagKey);
  }

  isFeatureFlagEnabled(featureFlagKey: FeatureFlagKey): boolean | undefined {
    return posthog.isFeatureEnabled(featureFlagKey);
  }
}
