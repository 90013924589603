import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from '@domgen/data-access-claims';
import { AppConfig, Claim } from '@domgen/dgx-components';
import { mergeMap, Subject, takeUntil } from 'rxjs';
import { SmartFixAnalyticsService } from '../../../services/smart-fix-analytics/smart-fix-analytics.service';
import { CONFIG } from '@domgen/dgx-fe-config';
import {
  chatBotFadeInAnimation,
  chatBotFadeInAnimationSequence,
} from '../../../animations/chatAnimations';

interface FeedbackOption {
  label: string;
  icon?: any;
  value: boolean | null;
  children?: FeedbackOption[];
}

@Component({
  selector: 'smart-fix-bedrock-feedback',
  templateUrl: './bedrock-feedback.component.html',
  styleUrls: ['./bedrock-feedback.component.scss'],
  animations: [chatBotFadeInAnimation, chatBotFadeInAnimationSequence],
})
export class BedrockFeedbackComponent implements OnInit, OnChanges, OnDestroy {
  @Input() feedback: string = '';
  @Output() requestFeedback = new EventEmitter<void>();
  @Output() closeChat = new EventEmitter<void>();
  private destroy$ = new Subject<void>();
  productType: string | undefined;

  onRequestFeedback() {
    this.requestFeedback.emit();
  }
  @Input() claim!: Claim;
  constructor(
    private smartFixAnalyticsService: SmartFixAnalyticsService,
    private apiService: ApiService,
    @Inject(CONFIG) protected readonly config: AppConfig
  ) {}

  ngOnInit() {
    this.productType =
      this.claim?.reflect?.productType?.toLowerCase() || 'appliance';
  }

  ngOnChanges() {
    if (this.feedback) {
      this.saveChatStatus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  feedbackOptions: FeedbackOption[] = [
    {
      label: `Yes, it has`,
      value: null,
    },
    {
      label: `No, it hasn't`,
      value: null,
    },
  ];
  window = window;
  readonly myAccountCTALabel = 'End, return to My Account';
  readonly continueRepairCTALabel = 'End, continue to repair booking';
  markOption(option: FeedbackOption) {
    const index = this.feedbackOptions.findIndex((value) => value === option);

    this.feedbackOptions.forEach((option) => (option.value = false));
    this.feedbackOptions[index].value = true;
    this.onRequestFeedback();

    const eventCategory = 'Smart-Fix Chatbot - ' + option.label;
    const label = option.label;
    const action = 'Has this resolved your issue?';
    this.trackFeedbackChoice(label, action, eventCategory);
  }

  async returnMyAccount() {
    const eventCategory = 'Smart-Fix Chatbot - ' + this.myAccountCTALabel;
    const label = this.myAccountCTALabel;
    const action = this.feedback;
    this.trackFeedbackChoice(label, action, eventCategory);
    this.window.location.href = this.config.myAccountUrl;
  }

  continueRepairBooking() {
    const eventCategory = 'Smart-Fix Chatbot - ' + this.continueRepairCTALabel;
    const label = this.continueRepairCTALabel;
    const action = this.feedback;
    this.trackFeedbackChoice(label, action, eventCategory);
    this.closeChat.emit();
  }

  saveChatStatus() {
    this.sendFeedback()
      .pipe(
        mergeMap(() => this.markAsComplete()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  sendFeedback() {
    return this.apiService.addBedrockChatFeedback(
      this.isResolved,
      this.feedback
    );
  }
  markAsComplete() {
    return this.apiService.markBedrockChatComplete();
  }

  get isResolved() {
    const selected = this.selectedOption;
    if (!selected) return false;
    return selected === this.feedbackOptions[0];
  }

  get selectedOption() {
    return this.feedbackOptions.find((option) => option.value === true);
  }

  trackFeedbackChoice(
    eventLabel: string,
    eventAction: string,
    eventCategory?: string
  ) {
    this.smartFixAnalyticsService.triggerSmartFixEvent(
      eventLabel,
      'fault-details-completed',
      this.claim,
      eventAction,
      eventCategory
    );
  }
}
