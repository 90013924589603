import { CspConfig, RuntimeConfig } from '@domgen/dgx-fe-config';
import { LightstepConfig } from '@domgen/dgx-fe-lightstep';
import { AppRuntimeFeatures } from './app-runtime-features.interface';

export interface AppRuntimeConfig extends RuntimeConfig {
  api: string;
  common: string;
  commonPersonServiceUrl: string;
  cookieProDomainScript: string;
  cookieProSrc: string;
  cookieUrlPattern: string;
  csp: CspConfig;
  channelCode: string;
  countryCode: string;
  dgHomePage: string;
  domain: string;
  lightstep: Partial<LightstepConfig>;
  myAccountApi: string;
  myAccountIdentity: string;
  myAccountLogoutPage: string;
  plansHomePage: string;
  personalDetailsPage: string;
  myPreferencesPage: string;
  runtimeFeatures: AppRuntimeFeatures;
  salesAppHost: string;
}
