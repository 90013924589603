import { mockCspConfig, mockRuntimeConfig } from '@domgen/dgx-fe-config';
import { mockLightstepConfig } from '@domgen/dgx-fe-lightstep';
import { AppRuntimeConfig } from '../../models/runtime/app-runtime-config.interface';
import { mockAppRuntimeFeatures } from './app-runtime-features.mock';

export const mockAppRuntimeConfig: AppRuntimeConfig = {
  ...mockRuntimeConfig,
  api: 'api',
  common: 'common',
  commonPersonServiceUrl: '',
  cookieProDomainScript: '',
  cookieProSrc: '',
  cookieUrlPattern: '',
  csp: mockCspConfig,
  channelCode: '',
  countryCode: '',
  dgHomePage: '',
  domain: '',
  lightstep: mockLightstepConfig,
  myAccountApi: 'myAccountApi',
  myAccountIdentity: 'myAccountIdentity',
  myAccountLogoutPage: '',
  plansHomePage: 'plansHomePage',
  personalDetailsPage: 'personalDetailsPage',
  myPreferencesPage: 'myPreferencesPage',
  runtimeFeatures: mockAppRuntimeFeatures,
  salesAppHost: 'salesAppHost',
};
