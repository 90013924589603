import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProductDetails } from '../../interfaces/product-details';

@Component({
  selector: 'dg-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent {
  @Input() product?: ProductDetails;
  @Input() rebrand = false;
  @Input() showSubHeader = true;
}
