const appleGadgetCodes = {
  I2: 'iPhone',
  I4: 'iPad',
  IO: 'iPod',
  M8: 'Apple Mac',
  M9: 'Apple Powerbook',
  IL: 'iPad',
};

const gadgetProductCodes = {
  DI: 'Digital Camera',
  DJ: 'In Car Dat',
  EB: 'Elec Pers Organiser / Handheld Computer',
  FG: 'Flashgun',
  FL: 'Floppy Disc',
  FM: 'Fax with Built-in Answering Machine',
  FX: 'Fax Machine',
  FY: 'Fax Machine with Telephone',
  GK: 'Games Console',
  GO: 'Global Positioning System',
  HC: 'Personal Computer',
  I3: 'Blackberry Phone',
  IB: 'Internet Access Box',
  IX: 'Laptop',
  IY: 'Laptop',
  IZ: 'Laptop',
  JE: 'Computer Base Unit',
  JF: 'Digital Camera & 2 Lenses',
  JG: 'Digital Camera, Lens & Flashgun',
  JH: 'Digital Camera, 2 Lenses & Flashgun',
  JI: 'Laptop',
  JJ: 'Desktop',
  JK: 'Laptop',
  JN: 'Desktop',
  JP: 'Desktop',
  JQ: 'Laptop',
  JR: 'PC Bundle',
  JY: 'Sony VR Headset',
  JZ: 'Occulus Rift / Vive VR Headset',
  LE: 'Camera Lens',
  LM: 'Light Meter',
  LR: 'SLR Camera',
  LT: 'Laptop',
  MN: 'Mini Note',
  MV: 'Combined Digital Camera/Mp3 Player',
  N7: 'Gamestick Console',
  N8: 'Playstation 4',
  N9: 'Xbox One',
  NA: 'Xbox One X',
  NC: 'Notebook Computer',
  NH: 'Portable Hard Drive',
  NL: 'Nintendo Ds',
  NN: 'Digital Scanner',
  NS: 'In Car Navigation System',
  NU: 'Nintendo Wii U',
  NW: 'Nintendo Wii',
  NX: 'Nintendo Switch',
  P7: 'Laptop And Printer Combination',
  PB: 'Personal Organiser',
  PN: 'Dictaphone',
  PR: 'Computer Printer',
  PY: 'PCM Recorder',
  Q: 'Digital Photo Frame',
  QB: 'Digital Scanner',
  QC: 'Personal Computer',
  QE: 'Peripheral',
  QI: 'Flat Screen Computer Monitor',
  QJ: 'Computer Monitor',
  QL: 'Computer Monitor',
  QM: 'Computer Monitor',
  QO: 'Router/Modem',
  QP: 'Printer',
  QQ: 'PC & Printer/Scanner',
  R8: 'Gaming Headphones',
  S1: 'Scanner',
  S7: 'Sports Equipment',
  S9: 'Samsung Galaxy Smartphone',
  SL: 'Slide Projector',
  SV: 'Stills Video Camera',
  T1: 'Dect Cordless Phone',
  T4: 'Gaming Chair',
  T5: 'Tablet Computer',
  TE: 'Telephone',
  TM: 'Telephone Answering Machine',
  TS: 'Telescope',
  TY: 'Electronic Typewriter',
  V8: 'Playstation Vita',
  V9: 'Ouya Console',
  VB: 'Personal Organiser',
  VC: 'Video Camera',
  VG: 'Camcorder',
  VL: 'Digital Camcorder',
  VM: 'Camcorder',
  VQ: 'TV Phone',
  VX: 'Camcorder',
  W1: 'Wearable Tech',
  WP: 'Word Processor',
  X9: 'Xbox Kinect',
  XI: 'Camera Accessories',
  XV: 'Carver Caravan Mover',
  XW: 'Xbox 360',
  XZ: 'Peripherals',
  YB: 'Playstation Portable',
  YN: 'Digital Photo Frame',
  YS: 'Playstation',
  YT: 'Playstation 2',
  YU: 'Playstation 3',
  YZ: 'Multi Media D-Snap',
  ZT: 'Telescope with Tripod',
  'C>': 'Mobile Phone With L&T',
  NY: 'Xbox Series X',
  NZ: 'Playstation 5',
  'P>': 'Mobile Phone',
};

export function isProductAGadget(
  productCode: string = '',
  manufacturer: string = ''
): 'GADGET' | 'APPLE' | undefined {
  console.log(productCode);
  console.log(manufacturer);

  const appleKeys = Object.keys(appleGadgetCodes);
  const gadgetKeys = Object.keys(gadgetProductCodes);

  // This line is required in case an iMac is returned as a Laptop gadget vs an apple specific product
  if (
    manufacturer.toLowerCase() === 'apple' &&
    (appleKeys.includes(productCode) || gadgetKeys.includes(productCode))
  )
    return 'APPLE';
  if (Object.keys(appleGadgetCodes).includes(productCode)) return 'APPLE';
  if (Object.keys(gadgetProductCodes).includes(productCode)) return 'GADGET';

  return undefined;
}
