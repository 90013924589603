<article *ngIf="vm$ | async as vm" class="{{ source }}">
  <h4 class="typog-body-bold">
    {{ provider?.ServiceProviderCompanyName }}
    {{ provider?.Distance ? '(' + provider?.Distance + ' miles)' : '' }}
  </h4>
  <p>
    {{
      provider?.ServiceProviderBuildingName
        ? provider?.ServiceProviderBuildingName + ', '
        : ''
    }}

    {{
      provider?.ServiceProviderHouseStreetName
        ? provider?.ServiceProviderHouseStreetName + ', '
        : ''
    }}

    {{
      provider?.ServiceProviderLocalArea
        ? provider?.ServiceProviderLocalArea + ', '
        : ''
    }}

    {{
      provider?.ServiceProviderTownCity
        ? provider?.ServiceProviderTownCity + ', '
        : ''
    }}
    {{ provider?.ServiceProviderPostCode }}

    <ng-container *ngIf="provider?.ServiceProviderTelephone">
      <br />
      Tel:
      <a href="tel:{{ provider?.ServiceProviderTelephone }}">{{
        provider?.ServiceProviderTelephone
      }}</a>
    </ng-container>
  </p>

  <section class="accordion {{ source }}" [class.active]="highlighted">
    <header>
      <h4 class="typog-caption-bold heading__highlight">
        <span>Opening hours</span>
        <i
          class="accordion__arrow"
          [ngClass]="{
            up: highlighted,
            down: !highlighted
          }"
        ></i>
      </h4>
    </header>

    <section *ngIf="highlighted" [@slideInOut] [@.disabled]="source === 'map'">
      <dg-opening-times
        [openingHours]="provider?.ServiceProviderHours"
      ></dg-opening-times>
    </section>
  </section>
</article>
<section class="list__action">
  <dg-fb-button
    text="{{ selected === index ? 'Selected' : 'Select' }}"
    classes="btn btn-quaternary-outline {{
      selected === index ? 'btn-active' : ''
    }}"
    (click)="setSelected(index, source)"
  ></dg-fb-button>
</section>
