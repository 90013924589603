import { LightstepDeploymentEnvironment } from '@domgen/dgx-fe-lightstep';

export const DEFAULT_ENVIRONMENT = {
  channelCode: 'DGX',
  countryCode: 'GB',
  requestSource: 'DandGUK',
  requestAction: 'MyAccount',
  buildFeatures: {},
  lightstep: {
    collectorUrl: 'https://ingest.lightstep.com/traces/otlp/v0.9',
    debug: false,
    deploymentEnvironment: LightstepDeploymentEnvironment.Development,
    serviceName: 'dg-claims-claims',
    serviceVersion: '1.0.0',
    traceHeaderWhitelist: [],
  },
  runtimeConfigPath: 'config.json',
  POSTHOG_HOST: 'https://eu.i.posthog.com',
};
